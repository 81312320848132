import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { dispatchDataToStore, StoreService } from '@studiohyperdrive/ngx-store';
import { catchAndCallThrough, ObservableArray, ObservableBoolean, validateContent } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { AlertType } from '@cjm/shared/ui/common';
import { LoadingModalComponent, ModalDialogService } from '@cjm/shared/ui/modal';
import {
	generateSnackbarConfig,
	generateSnackbarErrorConfig,
	SnackBarComponent,
	SnackBarService
} from '@cjm/shared/ui/toast';
import { IMainActivity } from '@cjm/v-loket/repositories';
import { AssociationDetailEntity, parseAssociation } from '@cjm/v-loket/shared';

import { I18nKeys } from '../../../i18n';
import { IRegistration } from '../../../ui';
import { IPostRegistrationConflictResponse, IPostRegistrationResponseEntity } from '../../interfaces';
import { RegisterAPIService } from '../../services';
import { actions, selectors } from '../../store';

@Injectable()
export class DuplicateFacade extends StoreService implements OnDestroy {
	private clearResponseOnDestroy: boolean = false;
	private readonly i18nKeys: typeof I18nKeys = I18nKeys;

	private readonly actionInProgressSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public readonly actionInProgress$: ObservableBoolean = this.actionInProgressSubject$.asObservable();

	private readonly conflictMessage$: Observable<IPostRegistrationConflictResponse> =
		this.selectErrorMessageFromStore<IPostRegistrationConflictResponse>(selectors.postRegistrationResponse).pipe(
			// Denis: Check if the error response is a conflict response
			filter(
				(error: IPostRegistrationConflictResponse) => !!error && error['@type'] === 'VerenigingscreatieConflict'
			),
			shareReplay()
		);

	/**
	 * This observable exposes the conflict response when a registration results in a positive duplicate check.
	 */
	public readonly conflictingAssociations$: ObservableArray<AssociationDetailEntity> = this.conflictMessage$.pipe(
		map(
			({ verenigingen: associations }: IPostRegistrationConflictResponse) =>
				(associations || []).map(parseAssociation) as AssociationDetailEntity[]
		)
	);

	/**
	 * This observable exposes the registration data from the register step (previous step).
	 */
	public readonly registrationData$: Observable<IRegistration> = this.selectFromStore(
		selectors.registerFormData
	).pipe(validateContent<IRegistration>(), shareReplay());

	/**
	 * This observable exposes the token to create the association whilst ignoring that it might be a duplicate.
	 */
	public readonly confirmationToken$: Observable<string> = this.conflictMessage$.pipe(
		map(({ bevestigingstoken: token }: IPostRegistrationConflictResponse) => token)
	);

	constructor(
		private readonly registerAPIService: RegisterAPIService,
		private readonly snackBarService: SnackBarService,
		private modalService: ModalDialogService,
		private readonly i18nService: NgxI18nService,
		protected readonly store: Store
	) {
		super(store);
	}

	public ngOnDestroy(): void {
		if (this.clearResponseOnDestroy) {
			this.store.dispatch(actions.postRegistrationResponse.clear());
		}
	}

	/**
	 * getMainActivities
	 *
	 * The getMainActivities will request the main activities from the API through registerAPIService.getMainActivities.
	 *
	 * @Returns Observable:IGetMainActivitiesResponse
	 */
	public getMainActivities(): ObservableArray<IMainActivity> {
		return this.registerAPIService.getMainActivities();
	}

	/**
	 * submitRegistration
	 *
	 * The submitRegistration method will attempt to register the association and dispatch the result to the store.the POST and store the response.
	 *
	 * @param data{IRegistration}
	 * @param token{string}
	 * @Returns Observable:IPostRegistrationResponseEntity
	 */
	public submitRegistration(data: IRegistration, token: string): Observable<IPostRegistrationResponseEntity> {
		this.clearResponseOnDestroy = true;

		this.actionInProgressSubject$.next(true);
		this.modalService.openModal(LoadingModalComponent);

		return dispatchDataToStore<IPostRegistrationResponseEntity>(
			actions.postRegistrationResponse,
			this.registerAPIService.postRegistrationData(data, token),
			this.store
		).pipe(
			tap(() => {
				// Denis: when the register request has been successful,
				// the duplicate check info cannot be cleared on destroy as it is needed in the redirect.
				this.clearResponseOnDestroy = false;
				this.actionInProgressSubject$.next(false);
			}),
			switchMap(() => this.modalService.closeModal()),
			catchAndCallThrough((error) => {
				// Denis: when the register request has not been successful,
				// the duplicate check info can be cleared on destroy to make it doesn't interfere with a new attempt.
				this.clearResponseOnDestroy = true;
				this.modalService.instantCloseModal();
				this.actionInProgressSubject$.next(false);

				if (!error || error.status === 409) {
					this.snackBarService.openFromComponent(
						SnackBarComponent,
						generateSnackbarConfig({
							type: AlertType.Error,
							title: this.i18nService.getTranslation(this.i18nKeys.Registration.Register.Error.Title),
							message: this.i18nService.getTranslation(
								this.i18nKeys.Registration.DuplicateCheck.Notices.CustomError.Message
							)
						})
					);

					return;
				}

				this.snackBarService.openFromComponent(
					SnackBarComponent,
					generateSnackbarErrorConfig(error, {
						type: AlertType.Error,
						title: this.i18nService.getTranslation(this.i18nKeys.Registration.Register.Error.Title)
					})
				);
			}, 'throw')
		);
	}
}

import { RouterModule } from '@angular/router';
import { NgxI18nGuard } from '@studiohyperdrive/ngx-i18n';

import { LoginHintGuard } from '@cjm/shared/authentication/auth';
import { MetaGuard, BaseComponent } from '@cjm/shared/core';
import { CJMRoute, CJMRoutes, VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { Language } from '@cjm/shared/types';
import { AssociationAuthenticatedGuard } from '@cjm/shared/user';
import { RegistrationRoutes } from '@cjm/v-loket/registration';

// Denis: Fallback routes
const LOKET_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Loket,
	redirectTo: VLoketAppRoutePaths.Empty
};

const EMPTY_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Empty,
	pathMatch: 'full',
	redirectTo: Language.NL
};

const FALLBACK_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Fallback,
	redirectTo: Language.NL
};

// Denis: Standalone libs
const ABOUT_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.About,
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/about').then((m) => m.AboutRoutes)
};

const ADVICE_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Advice,
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/advice').then((m) => m.AdviceRoutes)
};

const ASSOCIATIONS_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Associations,
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/associations').then((m) => m.AssociationRoutes)
};

const GLOBAL_SEARCH_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Search,
	loadChildren: () => import('@cjm/v-loket/global-search').then((m) => m.GlobalSearchRoutes)
};

const HOME_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Empty,
	canActivate: [AssociationAuthenticatedGuard],
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/home').then((m) => m.HomeRoutes)
};

const INFO_PAGE_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Info,
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/info').then((m) => m.InfoRoutes)
};

const MY_ASSOCIATION_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.MyAssociation,
	loadChildren: () => import('@cjm/v-loket/my-association/core').then((m) => m.MyAssociationCoreRoutes),
	data: {
		showRegisterKBOModal: true
	}
};

const PRODUCT_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Offers,
	data: {
		showRegisterKBOModal: true
	},
	loadChildren: () => import('@cjm/v-loket/products').then((m) => m.ProductRoutes)
};

const REPRESENTATIVE_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Representative,
	children: [
		{
			path: VLoketAppRoutePaths.Invitations,
			loadChildren: () =>
				import('@cjm/v-loket/representative/invitations').then((m) => m.RepresentativeInvitationRoutes)
		},
		{
			path: VLoketAppRoutePaths.Requests,
			loadChildren: () =>
				import('@cjm/v-loket/representative/requests').then((m) => m.RepresentativeRequestsRoutes)
		}
	]
};
const REDIRECT_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Redirects,
	loadChildren: () => import('@cjm/shared/redirects').then((m) => m.RedirectsRoutes)
};

// Denis: Eager loaded libs
const REGISTRATION_PATH: CJMRoute = {
	path: VLoketAppRoutePaths.Registration,
	// Denis: The Registration module is no longer lazy loaded so that we can show the modal on the welcome page.
	children: RegistrationRoutes
};

// Denis: Base route
const routes: CJMRoutes = [
	EMPTY_PATH,
	{
		path: VLoketAppRoutePaths.Language,
		pathMatch: 'prefix',
		canActivate: [NgxI18nGuard, MetaGuard, LoginHintGuard],
		canActivateChild: [NgxI18nGuard, MetaGuard, LoginHintGuard],
		component: BaseComponent,
		children: [
			HOME_PATH,
			PRODUCT_PATH,
			ASSOCIATIONS_PATH,
			MY_ASSOCIATION_PATH,
			LOKET_PATH,
			REGISTRATION_PATH,
			ABOUT_PATH,
			ADVICE_PATH,
			INFO_PAGE_PATH,
			REPRESENTATIVE_PATH,
			GLOBAL_SEARCH_PATH,
			REDIRECT_PATH,
			FALLBACK_PATH
		]
	},
	FALLBACK_PATH
];

export const AppRoutingModule = RouterModule.forRoot(routes, {
	scrollPositionRestoration: 'enabled',
	// Denis: enable anchor scrolling to make use of fragments within the routing.
	anchorScrolling: 'enabled',
	// Denis: set an offset of 150px on the Y axis
	// to offset the header and provide some margin above the anchor.
	scrollOffset: [0, 150]
});

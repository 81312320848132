import { NgClass } from '@angular/common';
import { HttpClient, HttpContext } from '@angular/common/http';
import { AfterViewInit, Component, Injector, OnDestroy, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { createCustomElement } from '@angular/elements';
import { MatDialogState } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ProfileConfig } from '@govflanders/vl-widget-global-header-types';
import { TranslateModule } from '@ngx-translate/core';
import { NgxCookieService } from '@studiohyperdrive/ngx-cookies';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { MatomoTracker } from 'ngx-matomo-client';
import {
	filter,
	fromEvent,
	takeUntil,
	tap,
	switchMap,
	combineLatest,
	Observable,
	map,
	take,
	distinctUntilChanged
} from 'rxjs';

import { CypressTagDirective } from '@cjm/cypress/core';
import { CypressTags } from '@cjm/cypress/shared';
import { InjectAcmComponentsService } from '@cjm/shared/authentication/acm';
import { AuthenticationService, SERVER_ERROR_HANDLER } from '@cjm/shared/authentication/auth';
import { CompanyService } from '@cjm/shared/company';
import { BrowserService, MetaService, SessionService } from '@cjm/shared/core';
import { NotificationFacade } from '@cjm/shared/notifications';
import { PwaService } from '@cjm/shared/pwa';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { StatusService, AppStatusEntity } from '@cjm/shared/status';
import { Language, OnDestroyComponent } from '@cjm/shared/types';
import { Breadcrumb, BreadcrumbService, BreadcrumbComponent } from '@cjm/shared/ui/breadcrumb';
import {
	CJMMenuItemEntity,
	LinkComponent,
	LinkType,
	HideMenuService,
	LayoutContainerComponent,
	MenuComponent,
	AlertType
} from '@cjm/shared/ui/common';
import { ActionModalComponent, ModalDialogService } from '@cjm/shared/ui/modal';
import { SidebarComponent } from '@cjm/shared/ui/sidebar';
import { generateSnackbarConfig, LoginSnackBarComponent, SnackBarService } from '@cjm/shared/ui/toast';
import { UserEntity, UserService } from '@cjm/shared/user';
import { getRouteData } from '@cjm/shared/utils';
import {
	AdviceDetailPageDividerComponent,
	AdviceDetailPageLinkBlockComponent,
	AdviceDetailPageRecommendedBlockComponent,
	AdviceDetailPageRichtTextComponent,
	AdviceDetailPageRoadmapComponent,
	AdviceDetailPageSplitComponent
} from '@cjm/v-loket/advice';
import { RegisterKboModalComponent } from '@cjm/v-loket/registration';
import { BigFooterEntity, TileBlockComponent, VloketBigFooterComponent } from '@cjm/v-loket/shared';
import { environment } from '~environment';

import { generateCookieConfig } from './cookie.const';
import { I18nKeys } from './markers';

@Component({
	selector: 'cjm-v-loket-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [NotificationFacade],
	imports: [
		NgClass,
		LayoutContainerComponent,
		MenuComponent,
		SidebarComponent,
		BreadcrumbComponent,
		RouterOutlet,
		VloketBigFooterComponent,
		CypressTagDirective,
		TranslateModule
	]
})
export class AppComponent extends OnDestroyComponent implements OnInit, OnDestroy, AfterViewInit {
	public readonly breadcrumbs: Signal<Breadcrumb[]> = toSignal(this.breadcrumbService.breadcrumbs$);
	public readonly showMenu: Signal<boolean> = toSignal(this.hideMenuService.isActive$);
	public readonly menuToggled: WritableSignal<boolean> = signal(false);
	public readonly language: Language = this.sessionService.language;
	public readonly paths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;
	public readonly i18nKey = I18nKeys;
	public containerOffsetTop = 0;

	public primaryMenuItems: CJMMenuItemEntity[] = [
		{
			id: I18nKeys.PageTitles.Home,
			routerLink: [this.language],
			cypressTag: 'Home.Nav.Home',
			exact: true
		},
		{
			id: I18nKeys.PageTitles.VLoket.Offers,
			routerLink: [this.language, this.paths.Offers],
			cypressTag: 'Home.Nav.Offers'
		},
		{
			id: I18nKeys.PageTitles.Associations,
			routerLink: [this.language, this.paths.Associations],
			cypressTag: 'Home.Nav.Associations'
		},
		{
			id: I18nKeys.PageTitles.VLoket.Advice,
			routerLink: [this.language, this.paths.Advice],
			cypressTag: 'Home.Nav.Advice'
		},
		{
			id: I18nKeys.PageTitles.AboutUs,
			routerLink: [this.language, this.paths.About],
			cypressTag: 'Home.Nav.AboutUs'
		}
	];

	public secondaryMenuItems: CJMMenuItemEntity[] = [
		{
			id: I18nKeys.PageTitles.VLoket.MyAssociation,
			routerLink: [this.language, this.paths.MyAssociation],
			cypressTag: 'Home.Nav.MyAssociation'
		}
	];

	public readonly bigFooterItems: BigFooterEntity[] = [
		{
			title: I18nKeys.PageTitles.Associations,
			links: [
				{
					title: I18nKeys.BigFooter.FindAssociation,
					url: [this.language, this.paths.Associations],
					type: LinkType.internal
				},

				{
					title: I18nKeys.BigFooter.Register,
					url: [this.language, this.paths.Registration, this.paths.RegistrationStart],
					type: LinkType.internal
				},
				{
					title: I18nKeys.BigFooter.BecomeRepresentative,
					url: [this.language, this.paths.Advice, 'vertegenwoordigers_in_het_verenigingsloket'],
					type: LinkType.internal
				}
			]
		},
		{
			title: I18nKeys.PageTitles.VLoket.Offers,
			links: [
				{
					title: I18nKeys.BigFooter.FindOffer,
					url: [this.language, this.paths.Offers],
					type: LinkType.internal
				}
			]
		},
		{
			title: I18nKeys.PageTitles.VLoket.Advice,
			links: [
				{
					title: I18nKeys.BigFooter.LegalInfo,
					url: [this.language, this.paths.Advice],
					queryParams: {
						filters: JSON.stringify({ thema: ['juridisch'] })
					},
					type: LinkType.internal
				},

				{
					title: I18nKeys.BigFooter.AdminInfo,
					url: [this.language, this.paths.Advice],
					queryParams: {
						filters: JSON.stringify({ thema: ['administratie'] })
					},
					type: LinkType.internal
				},
				{
					title: I18nKeys.BigFooter.AllThemes,
					url: [this.language, this.paths.Advice],
					type: LinkType.internal
				}
			]
		},
		{
			title: 'Verenigingsloket',
			links: [
				{
					title: I18nKeys.PageTitles.AboutUs,
					url: [this.language, this.paths.About],
					type: LinkType.internal
				},
				{
					title: I18nKeys.PageTitles.Partners,
					url: ['https://www.vlaanderen.be/verenigingsloket/lokaal-bestuur-of-vlaamse-overheidsdienst'],
					type: LinkType.external
				},
				{
					title: I18nKeys.PageTitles.Contact,
					url: [
						'https://us13.list-manage.com/contact-form?u=7558bdab8114a52b4e9c66d8e&form_id=5a64a1f78541d4e2f744f7f0593420ef'
					],
					type: LinkType.external
				},
				{
					title: I18nKeys.BigFooter.Newsletter,
					url: [
						'https://vlaanderen.us13.list-manage.com/subscribe?u=7558bdab8114a52b4e9c66d8e&id=dd7358dca0'
					],
					type: LinkType.external
				}
			]
		}
	];

	constructor(
		private readonly i18nService: NgxI18nService,
		private readonly sessionService: SessionService,
		private readonly userService: UserService,
		private readonly authService: AuthenticationService,
		private readonly companyService: CompanyService,
		private readonly breadcrumbService: BreadcrumbService,
		private readonly ngxCookieService: NgxCookieService,
		private readonly matomoTracker: MatomoTracker,
		private readonly browserService: BrowserService,
		private readonly notificationFacade: NotificationFacade,
		private readonly httpClient: HttpClient,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly statusService: StatusService,
		private readonly modalService: ModalDialogService,
		private readonly metaService: MetaService,
		private readonly injector: Injector,
		private readonly hideMenuService: HideMenuService,
		private readonly pwaService: PwaService,
		private readonly injectAcmComponentsService: InjectAcmComponentsService,
		private readonly snackBarService: SnackBarService
	) {
		super();

		this.i18nService.initI18n(this.language || Language.NL).subscribe();

		// This is the most important call. It fetches the user info from
		// the backend. This will trigger the user subscription in all
		// components. Only do interactions with the backend when a user's
		// status is know.
		this.userService.createUserSession().subscribe();

		this.browserService.runInBrowser(({ browserWindow }) => {
			// Listen to message events. When the popup sends this message we need
			// to redirect to the given destination. this allows an admin user to
			// be redirected from withing the header popup window.
			fromEvent(browserWindow, 'message')
				.pipe(
					tap((event: MessageEvent) => {
						if (!!event.data.type && event.data.type == 'cjm.postauth.redirect') {
							browserWindow.location.replace(event.data.destination);
						}
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		});

		this.browserService.runInBrowser(() => {
			this.httpClient.get('assets/json/app.json').subscribe(({ version }: { version: string }) => {
				console.log(`%c The current version of the app is: v${version}`, 'color: #31156B');
			});
		});

		// Abdurrahman: Subscribe to the analytics cookie consent
		this.ngxCookieService
			.hasAcceptedService('analytics', 'ma')
			.pipe(
				distinctUntilChanged(),
				tap((hasAccepted) => {
					//Abdurrahman: If Matomo Analytics was accepted, we will enable it
					// If not, we will disable it
					if (hasAccepted) {
						this.matomoTracker.rememberCookieConsentGiven();
					} else {
						this.matomoTracker.forgetCookieConsentGiven();
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		if (this.browserService.isBrowser) {
			const linkComponent = createCustomElement(LinkComponent, { injector: this.injector });
			const adviceTileBlockComponent = createCustomElement(TileBlockComponent, {
				injector: this.injector
			});
			const adviceSplitComponent = createCustomElement(AdviceDetailPageSplitComponent, {
				injector: this.injector
			});
			const adviceRoadmapComponent = createCustomElement(AdviceDetailPageRoadmapComponent, {
				injector: this.injector
			});
			const adviceRichTextComponent = createCustomElement(AdviceDetailPageRichtTextComponent, {
				injector: this.injector
			});
			const adviceRecomendedComponent = createCustomElement(AdviceDetailPageRecommendedBlockComponent, {
				injector: this.injector
			});
			const adviceLinkBlockComponent = createCustomElement(AdviceDetailPageLinkBlockComponent, {
				injector: this.injector
			});
			const adviceDividerComponent = createCustomElement(AdviceDetailPageDividerComponent, {
				injector: this.injector
			});

			customElements.define('vlo-link', linkComponent);
			customElements.define('vlo-advice-detail-page-tile-block', adviceTileBlockComponent);
			customElements.define('vlo-advice-detail-page-tile-split', adviceSplitComponent);
			customElements.define('vlo-advice-detail-page-roadmap', adviceRoadmapComponent);
			customElements.define('vlo-advice-detail-page-richtext', adviceRichTextComponent);
			customElements.define('vlo-advice-detail-page-recomended-block', adviceRecomendedComponent);
			customElements.define('vlo-advice-detail-page-link-block', adviceLinkBlockComponent);
			customElements.define('vlo-advice-detail-page-divider', adviceDividerComponent);
		}
	}

	public ngOnInit(): void {
		//	Abdurrahman: Initialize Matomo with the required consent and cookie consent
		this.matomoTracker.requireCookieConsent();
		this.matomoTracker.trackPageView();

		// Iben: Redirect to the maintenance page if the status call fails
		combineLatest([this.statusService.status$, this.pwaService.isOnline$])
			.pipe(
				filter(([{ isActive }]: [AppStatusEntity, boolean]) => !isActive),
				tap(([, isOnline]: [AppStatusEntity, boolean]) => {
					this.router.navigate([
						this.language,
						VLoketAppRoutePaths.Redirects,
						isOnline ? VLoketAppRoutePaths.Maintenance : VLoketAppRoutePaths.Offline
					]);
				})
			)
			.subscribe();

		this.browserService.runInBrowser(({ browserDocument }) => {
			// Abdurrahman: Subscribe to the router events
			this.router.events
				.pipe(
					tap(() => {
						this.metaService.addCanonicalLink(browserDocument);
					})
				)
				.subscribe();

			// Denis: Fetching and showing the notifications should only happen on the client.
			// This functionality also requires access to localStorage.
			this.notificationFacade.init().subscribe();
		});

		this.route.queryParams
			.pipe(
				tap((params) => {
					if (params?.remoteAuthSuccess && params?.remoteAuthSuccess === 'false') {
						this.snackBarService
							.openFromComponent(
								LoginSnackBarComponent,
								generateSnackbarConfig({
									id: 'remote-auth-failed',
									title: this.i18nService.getTranslation(this.i18nKey.SnackBars.ErrorTitle),
									message: this.i18nService.getTranslation(this.i18nKey.Notices.AuthFailed.Text),
									type: AlertType.Error,
									duration: 10000,
									loginButton: {
										title: this.i18nService.getTranslation(this.i18nKey.Common.Login),
										text: this.i18nService.getTranslation(this.i18nKey.Common.Login)
									}
								}),
								true
							)
							.afterDismissed();
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Denis: Get the app status and feature flags
		this.statusService.getAppStatus().pipe(takeUntil(this.destroyed$)).subscribe();

		// Iben: Track the update status of the application, if there are updates available, show a modal
		this.statusService.updateAvailable$
			.pipe(
				filter(Boolean),
				switchMap(() => this.handleServiceWorkerChanges()),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Iben: Do a location reload whenever there's an error event in the registered service worker
		this.statusService.serviceWorkerErrorEvent$
			.pipe(
				filter(Boolean),
				tap(() => {
					// Iben: Remove the latest cache to ensure that next time we get a version from the server
					this.browserService.runInBrowser(({ browserWindow }) => {
						browserWindow.caches.open('ngsw:/:db:control').then((cache) => cache.delete('/latest'));
					});
				})
			)
			.subscribe();

		this.notificationFacade.emitGlobalNotifications().subscribe();

		this.setUpKBOModalSubscription().subscribe();

		/**
		 * We listen to the broadcast channel to see if the user session has changed in another tab or window
		 * within the same browser context.
		 */
		this.userService.sessionChanged$
			.pipe(
				filter((changed: boolean) => changed),
				switchMap(() => {
					const modalRef = this.modalService.openModal(ActionModalComponent);

					modalRef.component.title = this.i18nService.getTranslation(
						this.i18nKey.Notices.SessionChanged.Title
					);
					modalRef.component.text = this.i18nService.getTranslation(this.i18nKey.Notices.SessionChanged.Text);
					modalRef.component.confirmButton = {
						text: this.i18nService.getTranslation(this.i18nKey.Notices.SessionChanged.Button),
						title: this.i18nService.getTranslation(this.i18nKey.Notices.SessionChanged.Button)
					};

					return modalRef.component.buttonClicked;
				}),
				tap(() => {
					this.browserService.refresh();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public ngAfterViewInit(): void {
		// Abdurrahman: Generate the cookie configuration
		const { categories, language, configuration } = generateCookieConfig(this.language, environment.production);

		// Abdurrahman: Set up the cookies handler
		this.ngxCookieService.setupCookiesHandler(categories, language, configuration);

		this.userService.loading$
			.pipe(
				filter((loading: boolean) => !loading),
				switchMap(() => this.userService.user$),
				switchMap((user: UserEntity) => {
					return this.injectAcmComponentsService.initHeader({
						selector: '#c-header__block__acm-header',
						config: {
							url: environment.acmidm.widgetsUrl,
							id: environment.acmidm.headerWidgetId,
							profile: this.parseGlobalHeaderConfig(user)
						},
						links: [
							{
								icon: 'e-desk',
								href: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Registration}/${VLoketAppRoutePaths.RegistrationStart}`,
								label: 'Registreer een vereniging'
							}
						]
					});
				})
			)
			.subscribe();

		combineLatest([
			this.i18nService.getTranslationObservable(this.i18nKey.Footer.Links.Accessibility),
			this.i18nService.getTranslationObservable(this.i18nKey.Footer.Links.CookiePolicy),
			this.i18nService.getTranslationObservable(this.i18nKey.Footer.Links.CookieSettings),
			this.i18nService.getTranslationObservable(this.i18nKey.Footer.Links.PrivacyPolicy),
			this.i18nService.getTranslationObservable(this.i18nKey.Footer.Links.TermsOfUse),
			this.i18nService.getTranslationObservable(this.i18nKey.Footer.Links.Complaints)
		])
			.pipe(
				switchMap(([accessibility, cookiePolicy, cookieSettings, privacyPolicy, termsOfUse, complaints]) => {
					return this.injectAcmComponentsService.initFooter({
						selector: '#c-footer__acm-footer',
						config: {
							url: environment.acmidm.widgetsUrl,
							id: environment.acmidm.footerWidgetId
						},
						links: [
							{
								href: `/${this.i18nService.currentLanguage}/${environment.acmidm.footerLinks.Accessibility}`,
								label: accessibility
							},
							{
								href: `/${this.i18nService.currentLanguage}/${environment.acmidm.footerLinks.CookiePolicy}`,
								label: cookiePolicy
							},
							{
								href: environment.acmidm.footerLinks.CookieSettings,
								label: cookieSettings
							},
							{
								href: `/${this.i18nService.currentLanguage}/${environment.acmidm.footerLinks.PrivacyPolicy}`,
								label: privacyPolicy
							},
							{
								href: `/${this.i18nService.currentLanguage}/${environment.acmidm.footerLinks.TermsOfUse}`,
								label: termsOfUse
							},
							{
								href: environment.acmidm.footerLinks.Complaints,
								label: complaints,
								target: '_blank'
							}
						]
					});
				}),
				tap(() => {
					// Denis: This setTimeout is needed to ensure that the button is rendered before we try to attach the event listener
					setTimeout(() => {
						this.setupCookieSettingsButton();
					});
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/**
	 * Opens the cookies popup
	 */
	public openCookiesPopup(): void {
		this.ngxCookieService.showModal();
	}

	/**
	 * menuToggle
	 *
	 * The menuToggle is connected to the menu component and will keep track of the toggled state.
	 *
	 * @param toggled{boolean}
	 */
	public menuToggle(toggled: boolean): void {
		this.menuToggled.set(toggled);
	}

	/**
	 * setUpKBOModalSubscription
	 *
	 * The setUpKBOModalSubscription method will listen to the route data to see if the
	 * KBO register modal should be shown.
	 *
	 * @private
	 * @returns Observable<unknown>
	 */
	private setUpKBOModalSubscription(): Observable<unknown> {
		const hideForSession = this.sessionService.getSessionItem(
			'hide-register-kbo-modal',
			(value: string) => value === 'true'
		);
		let KBOModalRef;

		return this.router.events.pipe(
			// Denis: The `getRouteData` will het the data object from the primary router outlet
			getRouteData(this.route),
			// Denis: Filter to see if
			// 	- the showRegisterKBOModal is true,
			// 	- if there is a KBOModalRef
			filter(
				({ showRegisterKBOModal }: { showRegisterKBOModal: boolean }) =>
					!!showRegisterKBOModal &&
					!hideForSession &&
					KBOModalRef?.reference?.getState() !== MatDialogState.OPEN
			),
			// Denis: Wait for the user to stop loading
			switchMap(() => this.userService.loading$),
			filter((loading: boolean) => !loading),
			// Denis: Check if the user is logged in as a company and if the company is registrable.
			switchMap(() => combineLatest([this.userService.isCompany$, this.userService.isRegisterableEA()])),
			filter(([isCompany, isRegisterableEA]: [boolean, boolean]) => isCompany && isRegisterableEA),
			// Denis: Get the user data
			switchMap(() => this.userService.user$),
			// Denis: check if the KBO modal needs to be hidden for the remaining session
			filter(
				({ company }: UserEntity) =>
					!this.sessionService.getSessionItem(
						`hide-register-kbo-modal.${company.number}`,
						(value: string) => value === 'true'
					)
			),
			// Denis: Get the company data and store it
			switchMap(({ company }: UserEntity) =>
				this.companyService.getCompany(company.number, new HttpContext().set(SERVER_ERROR_HANDLER, false))
			),
			// Denis: Open the modal
			tap(() => {
				KBOModalRef = this.modalService.openModal(RegisterKboModalComponent);
			}),
			takeUntil(this.destroyed$)
		);
	}

	/**
	 * Show a modal when the service worker gets a new version of the application
	 */
	private handleServiceWorkerChanges(): Observable<void> {
		const translations = this.i18nKey.Maintenance.Update;

		// Iben: Fetch the translation strings
		return combineLatest([
			this.i18nService.getTranslationObservable(translations.Title),
			this.i18nService.getTranslationObservable(translations.Text),
			this.i18nService.getTranslationObservable(translations.Continue)
		]).pipe(
			// Iben: Only take the first value
			take(1),
			// Iben: Switchmap the translations to the close event of the modal
			switchMap(([title, text, confirmLabel]) => {
				// Iben: Set the details of the modal
				const modal = this.modalService.openModal<ActionModalComponent>(ActionModalComponent);
				modal.component.title = title;
				modal.component.text = text;
				modal.component.confirmButton = {
					text: confirmLabel,
					title: confirmLabel
				};

				// Iben: Switchmap to the close event
				return modal.component.buttonClicked.pipe(
					// Iben: Close the modal and reload the page
					tap(() => {
						this.modalService.closeModal();
					}),
					// Iben: Switchmap to update service worker
					switchMap(() => this.statusService.updateApplication()),
					// Iben: In case the update failed, we do a hard reset
					tap(() => {
						location.reload();
					}),
					// Iben: Discard the return value of the observables
					map(() => undefined),
					takeUntil(this.destroyed$)
				);
			})
		);
	}

	/**
	 * parseGlobalHeaderConfig
	 *
	 * The parseGlobalHeaderConfig method will parse the user data to the profile config.
	 *
	 * @param user{UserEntity}
	 * @returns ProfileConfig
	 * @private
	 */
	private parseGlobalHeaderConfig(user: UserEntity): ProfileConfig {
		const url = environment.api.protocol + '://' + environment.api.hostname;

		return {
			active: Boolean(user),
			loginUrl: `${url}${environment.acmidm.loginPath}${this.authService.getLocalRedirectUrl('?')}`,
			logoutUrl: `${url}${environment.acmidm.logoutPath}${this.authService.getLocalRedirectUrl('?')}`,
			switchCapacityUrl: `${url}${environment.acmidm.switchPath}${this.authService.getLocalRedirectUrl('?')}`,
			// Denis: If the user is logged in, set the user data in the profile config
			...(user
				? {
						idpData: {
							user: {
								firstName: user.firstName,
								name: user.name
							},
							// Denis: If the user is logged in for an organization, set the organization data in the profile config
							...(user?.company
								? {
										activeIdentity: {
											onBehalfOf: {
												code: user.targetCode,
												name: user.company.name,
												identifier: user.company.number
											}
										}
									}
								: {})
						}
					}
				: {})
			// TODO: Denis: The interfaces have a small error that will be fixed, cast the interface for now until fixed
		} as ProfileConfig;
	}

	/**
	 * setupCookieSettingsButton
	 *
	 * The setupCookieSettingsButton method is responsible for creating a new button element in the footer
	 * that will trigger the openCookieConsent EventEmitter.
	 *
	 * @private
	 */
	private setupCookieSettingsButton(): void {
		this.browserService.runInBrowser(({ browserDocument }) => {
			// Denis: The footer is a shadow DOM element, we need to get the shadow root to access the elements
			const shadowRoot: ShadowRoot = browserDocument.querySelector('footer > div > div')?.shadowRoot;
			// Denis: Find the `a` element with the specific `/nl/pagina/cookiebeleid` value
			const cookiePolicyLink: HTMLElement = shadowRoot.querySelector('.list-item-link[href="#cookie-settings"]');

			// Denis: If the element is not found, we early exit
			if (!cookiePolicyLink) {
				return;
			}

			// Denis: The cookiePolicyLink is a link, we need to apply some changes to make it act as a button.
			cookiePolicyLink.removeAttribute('href');
			cookiePolicyLink.setAttribute('role', 'button');
			cookiePolicyLink.setAttribute('data-cy', CypressTags.Footer.Links.CookieConsent);
			cookiePolicyLink.style.cursor = 'pointer';
			cookiePolicyLink.addEventListener('click', (event) => {
				event.stopPropagation();
				event.preventDefault();

				this.openCookiesPopup();
			});
		});
	}
}

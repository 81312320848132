import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { WithRouterLinkPipe } from '@studiohyperdrive/ngx-utils';

import { SessionService } from '@cjm/shared/core';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { AlertType } from '@cjm/shared/ui/common';
import { SnackBarService, ToasterComponent } from '@cjm/shared/ui/toast';

import { I18nKeys } from '../../../i18n';

export const SESSION_KEY = 'hide-register-kbo-notice';

@Component({
	templateUrl: './register-kbo-snackbar.component.html',
	imports: [ToasterComponent, TranslateModule, WithRouterLinkPipe]
})
export class RegisterKboSnackbarComponent {
	public readonly alertTypes: typeof AlertType = AlertType;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly vloketAppRoutes: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;

	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public readonly data: { name: string },
		protected readonly snackBarService: SnackBarService,
		private readonly sessionService: SessionService,
		public readonly i18nService: NgxI18nService
	) {}

	/**
	 * closeSnackbar
	 *
	 * The closeSnackbar method will hide the modal until the page is revisited.
	 */
	public closeSnackbar(): void {
		this.sessionService.setSessionItem(SESSION_KEY, true);

		this.snackBarService.dismiss();
	}
}

import { HttpBackend } from '@angular/common/http';
import { NgxI18nMultiTranslationHttpLoader } from '@studiohyperdrive/ngx-i18n';

export function RegistrationTranslationLoader(http: HttpBackend) {
	return new NgxI18nMultiTranslationHttpLoader(http, [
		'./assets/i18n/shared/i18n/',
		'./assets/i18n/shared/company/',
		'./assets/i18n/shared/ui/forms/',
		'./assets/i18n/shared/ui/common/',
		'./assets/i18n/v-loket/registration/',
		'./assets/i18n/v-loket/shared/'
	]);
}

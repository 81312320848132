import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormAccessor } from '@studiohyperdrive/ngx-forms';

import { I18nKeys } from '../../../i18n';

import {
	IAdditionalRegistrationDataForm,
	IAdditionalRegistrationDataFormGroup
} from './additional-registration-data-form.types';

@Component({
	selector: 'vloket-additional-registration-data-form',
	templateUrl: './additional-registration-data-form.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AdditionalRegistrationDataFormComponent),
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ReactiveFormsModule, TranslateModule]
})
export class AdditionalRegistrationDataFormComponent extends FormAccessor<
	IAdditionalRegistrationDataForm,
	FormGroup<IAdditionalRegistrationDataFormGroup>
> {
	public readonly i18nKeys = I18nKeys;

	// Denis: See: libs/shared/ui/forms/src/lib/abstracts/readme.md
	public initForm(): FormGroup {
		return new FormGroup({
			associationCallname: new FormControl<string>(''),
			associationDescription: new FormControl<string>(''),
			associationFoundingDate: new FormControl<string>('')
		});
	}
}

import { Component, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgxErrorsModule } from '@ngspot/ngx-errors';
import { TranslateModule } from '@ngx-translate/core';
import { FormAccessorContainer } from '@studiohyperdrive/ngx-forms';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { catchAndCallThrough, validateContent } from '@studiohyperdrive/rxjs-utils';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CypressTagDirective } from '@cjm/cypress/core';
import { BrowserService, MetaService } from '@cjm/shared/core';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { CJMError } from '@cjm/shared/types';
import {
	AlertComponent,
	AlertType,
	ButtonClasses,
	ButtonComponent,
	FaIconComponent,
	InfoComponent,
	LayoutContainerComponent
} from '@cjm/shared/ui/common';
import { CheckBoxComponent, GetFormGroupPipe } from '@cjm/shared/ui/forms';
import { generateSnackbarConfig, SnackBarComponent, SnackBarService } from '@cjm/shared/ui/toast';
import { UserEntity } from '@cjm/shared/user';
import { formatCJMErrorDetails, getDuplicateEntryKeysUtil, prefixObjectKeysUtil } from '@cjm/shared/utils';
import { IMainActivity } from '@cjm/v-loket/repositories';
import {
	AssociationType,
	IRepresentativeForm,
	SendCopyForm,
	RepresentativeFormComponent,
	SendCopyComponent
} from '@cjm/v-loket/shared';

import { RegisterFacade } from '../../../data';
import { I18nKeys } from '../../../i18n';
import { BasicRegistrationDataFormComponent, IBasicRegistrationDataForm } from '../../components';

import { IRegistration, IRegistrationForm } from './register.types';

const PRIMARY_INVITE_KEY = 'invite-0';

@Component({
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	providers: [RegisterFacade],
	imports: [
		ReactiveFormsModule,
		NgxErrorsModule,
		LayoutContainerComponent,
		BasicRegistrationDataFormComponent,
		FaIconComponent,
		RepresentativeFormComponent,
		ButtonComponent,
		InfoComponent,
		SendCopyComponent,
		CheckBoxComponent,
		CypressTagDirective,
		RouterLink,
		TranslateModule,
		GetFormGroupPipe,
		AlertComponent
	]
})
export class RegisterPageComponent extends FormAccessorContainer implements OnInit {
	public readonly actionInProgress: Signal<boolean> = toSignal(this.registerFacade.actionInProgress$);
	public readonly mainActivities: Signal<IMainActivity[]> = toSignal(this.registerFacade.getMainActivities());
	public readonly inviteKeys: WritableSignal<string[]> = signal<string[]>([PRIMARY_INVITE_KEY]);
	public readonly duplicateInviteKeys: WritableSignal<string[]> = signal<string[]>([]);
	public readonly errorDetails: WritableSignal<string> = signal<string>(null);

	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly appRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly associationTypes: typeof AssociationType = AssociationType;
	public readonly submitRegistration = debounce<() => void>(this.validateAndSubmit.bind(this), 600);

	private readonly basicInfoFormInitialized$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private readonly registerFacade: RegisterFacade,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly snackBarService: SnackBarService,
		private readonly i18nService: NgxI18nService,
		private readonly metaService: MetaService,
		private readonly browserService: BrowserService
	) {
		super();
	}

	// Denis: The following form results in an IRegistration object
	public registerForm: FormGroup<IRegistrationForm> = new FormGroup(
		{
			basicInfo: new FormControl<IBasicRegistrationDataForm>(
				{
					associationType: this.associationTypes.FV,
					associationAddress: {
						country: 'België'
					}
				} as IBasicRegistrationDataForm,
				{
					validators: [Validators.required]
				}
			),
			representative: new FormControl<IRepresentativeForm>(null, {
				validators: [Validators.required]
			}),
			invites: new FormGroup<{ [key: string]: FormControl<IRepresentativeForm> }>({
				[PRIMARY_INVITE_KEY]: new FormControl<IRepresentativeForm>(null, {
					validators: [Validators.required]
				})
			}),
			sendCopy: new FormControl<SendCopyForm>({
				isActive: false,
				recipient: ''
			}),
			subscribeToNewsletter: new FormControl<boolean>(false)
		},
		{
			validators: [this.uniqueRepresentativeINSZValidator()]
		}
	);

	public ngOnInit(): void {
		this.metaService.updateMetaData(
			{
				title: this.i18nService.getTranslation(this.i18nKeys.Registration.Register.Title),
				description: this.i18nService.getTranslation(this.i18nKeys.Registration.Register.BasicData.Description),
				pageUrl: this.router.url
			},
			// Abdurrahman: this page shouldn't be indexed
			false
		);

		// Denis: When the user is logged in, pre-fill the first representative formGroup with their information.
		this.registerFacade.user$
			.pipe(
				validateContent(),
				tap(({ firstName, name, insz }: UserEntity) => {
					this.registerForm.get('representative').patchValue(
						{
							representativeFirstnameField: firstName,
							representativeSurnameField: name,
							representativeRRNField: insz
						} as IRepresentativeForm,
						{ emitEvent: false }
					);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Denis: if there is existing form data, patch it to the form (restore after duplicate check)
		// It is important that we wait for the basic form to be initialized, to make sure the form is fully rendered.
		this.basicInfoFormInitialized$
			.pipe(
				validateContent(),
				switchMap(() => this.registerFacade.existingRegistrationData$),
				first(),
				validateContent(),
				// Denis: Make sure enough invite controls are available for the existing invite data.
				tap((data: IRegistration) => {
					const existingInvites = Object.keys(data.invites);
					const invites = [...existingInvites].slice(1, existingInvites.length);

					invites.forEach(() => {
						this.addRepresentative();
					});
				}),
				// Denis: Patch the existing form data to the form
				tap((data: IRegistration) => this.registerForm.patchValue(data)),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Denis: subscribe to the representatives formArray valueChanges to update the sendCopy formControl
		this.registerForm
			.get('representative')
			.valueChanges.pipe(
				// Denis: only move forward when the email address changes
				distinctUntilChanged(
					(current: IRepresentativeForm, previous: IRepresentativeForm) =>
						current.representativeEmailField === previous.representativeEmailField
				),
				// Denis: only move forward when an email address is provided and the sendCopy component is not active.
				filter((representative: IRepresentativeForm) => {
					const email = !!representative.representativeEmailField;
					const sendCopyActive = this.registerForm.get('sendCopy').value?.isActive;

					return email && !sendCopyActive;
				}),
				// Denis: patch the sendCopy formControl with the email address of the first representative
				tap((representative: IRepresentativeForm) => {
					const email = representative.representativeEmailField;

					this.registerForm.get('sendCopy').patchValue(
						{
							isActive: false,
							recipient: email
						},
						{ emitEvent: false }
					);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		this.registerForm
			.get('sendCopy')
			.valueChanges.pipe(
				// Denis: only move forward when the sendCopy.isActive value has changed
				distinctUntilChanged(
					(current: SendCopyForm, previous: SendCopyForm) => current.isActive === previous.isActive
				),
				tap((sendCopy: SendCopyForm) => {
					const recipient = this.registerForm.get('representative').value.representativeEmailField;

					// Denis: when the sendCopy component is active and the recipient is not the same as the first representative, update the recipient.
					if (sendCopy.isActive && recipient && sendCopy.recipient !== recipient) {
						this.registerForm.get('sendCopy').patchValue(
							{
								isActive: true,
								recipient
							},
							{ emitEvent: false }
						);
					}

					// Denis: when the sendCopy component is not active, reset the recipient.
					if (!sendCopy.isActive) {
						this.registerForm.get('sendCopy').patchValue(
							{
								isActive: false,
								recipient: ''
							},
							{ emitEvent: false }
						);
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/**
	 * validateAndSubmit
	 *
	 * The validateAndSubmit method will trigger the submitRegistration method on the registerFacade with the registerForm value.
	 */
	private validateAndSubmit(): void {
		this.registerForm.markAsDirty();
		this.updateAllValueAndValidity(this.registerForm);
		this.registerForm.updateValueAndValidity();

		if (this.registerForm.invalid) {
			this.snackBarService.openFromComponent(
				SnackBarComponent,
				generateSnackbarConfig({
					title: this.i18nService.getTranslation(
						this.i18nKeys.Registration.Register.FormNotice.InvalidFormTitle
					),
					message: this.i18nService.getTranslation(
						this.i18nKeys.Registration.Register.FormNotice.InvalidForm
					),
					type: AlertType.Warning
				})
			);
			this.browserService.scrollToElement('.ng-invalid .c-input__description.is-error').subscribe();

			return;
		}

		const value = this.registerForm.getRawValue();

		this.registerFacade
			.submitRegistration(value)
			.pipe(
				tap(() => {
					this.router.navigate(['..', this.appRoutePaths.RegistrationRedirectRegistration], {
						relativeTo: this.route
					});
				}),
				catchAndCallThrough(({ error }: { error: CJMError }) => {
					this.errorDetails.set(formatCJMErrorDetails(error));
				}, 'throw'),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/**
	 * addRepresentative
	 *
	 * The addRepresentative method will push a new FormControl to the representatives FormArray.
	 */
	public addRepresentative(): void {
		// Denis: Type needs to be cast because `.get()` returns an AbstractControl.
		const inviteGroup = this.registerForm.get('invites') as FormGroup;
		const inviteKey = `invite-${this.inviteKeys()?.length}`;

		// Denis: Add a new control to the invites FormGroup
		inviteGroup.addControl(inviteKey, new FormControl<IRepresentativeForm>(null), { emitEvent: false });

		// Denis: Add the key to the inviteKeys signal
		this.inviteKeys.set([...this.inviteKeys(), inviteKey]);
	}

	/**
	 * removeRepresentative
	 *
	 * The removeRepresentative method will remove an existing FormControl to the representatives FormArray.
	 */
	public removeRepresentative(inviteKey: string): void {
		// Denis: Type needs to be cast because `.get()` returns an AbstractControl.
		const inviteGroup = this.registerForm.get('invites') as FormGroup;

		// Denis: Remove the control from the invites FormGroup
		inviteGroup.removeControl(inviteKey);

		// Denis: Remove the key from the inviteKeys signal
		this.inviteKeys.set(this.inviteKeys().filter((key) => key !== inviteKey));
	}

	/**
	 * basicDataFormInitialized
	 *
	 * The basicDataFormInitialized method will push the initialized value of the basic data form to an internal subject.
	 *
	 * @param isInitialized
	 */
	public basicDataFormInitialized(isInitialized: boolean): void {
		if (!isInitialized) {
			return;
		}

		this.basicInfoFormInitialized$.next(isInitialized);
	}

	/**
	 * uniqueRepresentativeINSZValidator
	 *
	 * The uniqueRepresentativeINSZValidator method will return a ValidatorFn that will check if the representative INSZ numbers are unique.
	 *
	 * @returns ValidatorFn
	 */
	private uniqueRepresentativeINSZValidator(): ValidatorFn {
		return (formGroup: FormGroup<IRegistrationForm>): ValidationErrors | null => {
			// Denis: reset the duplicateInviteKeys signal
			this.duplicateInviteKeys.set([]);

			// Denis: Get the current value of the formGroup
			const currentValue = formGroup?.value;

			// Denis: Create a flat object of all representatives (main + invites).
			const representatives = {
				representative: currentValue?.representative,
				...prefixObjectKeysUtil(currentValue?.invites)
			};

			// Denis: Use the getDuplicateEntryKeysUtil to get the duplicate keys of the formControls with duplicate INSZ numbers.
			const valueMap = getDuplicateEntryKeysUtil(representatives, 'representativeRRNField', (value: string) =>
				value.toString().replace(/\D/g, '')
			);

			// Denis: Get the current duplicates and add them to the duplicateInviteKeys as a Set.
			this.duplicateInviteKeys.set(valueMap);

			// Denis: If there are duplicates, return an error on the FormGroup to prevent submission (invalid form).
			if (this.duplicateInviteKeys().length > 0) {
				return { duplicateINSZError: true };
			}

			return null;
		};
	}
}

import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { dispatchDataToStore, StoreService } from '@studiohyperdrive/ngx-store';
import { catchAndCallThrough, ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject, catchError, Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AlertType } from '@cjm/shared/ui/common';
import { LoadingModalComponent, ModalDialogService } from '@cjm/shared/ui/modal';
import { catchWithSnackBar, generateSnackbarConfig, SnackBarComponent, SnackBarService } from '@cjm/shared/ui/toast';
import { UserEntity, UserService } from '@cjm/shared/user';
import { IMainActivity } from '@cjm/v-loket/repositories';
import { IRepresentativeForm } from '@cjm/v-loket/shared';

import { I18nKeys } from '../../../i18n';
import { IRegistration } from '../../../ui';
import { IPostRegistrationResponseEntity, PostRegistrationNotificationResponseEnity } from '../../interfaces';
import { RegisterAPIService } from '../../services';
import { actions, selectors } from '../../store';

@Injectable()
export class RegisterFacade extends StoreService {
	private readonly actionInProgressSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public readonly actionInProgress$: ObservableBoolean = this.actionInProgressSubject$.asObservable();

	/**
	 * Loading state of the associations
	 */
	public readonly isRegistrationSubmitting$: ObservableBoolean = this.selectLoadingFromStore(
		selectors.postRegistrationResponse
	);

	/**
	 * This observable exposes the user info from the store through the UserService.
	 */
	public readonly user$: Observable<UserEntity> = this.userService.user$;

	/**
	 * This observable exposes the current form data in the store.
	 */
	public readonly existingRegistrationData$: Observable<IRegistration> = this.selectFromStore(
		selectors.registerFormData
	);

	private readonly i18nKeys: typeof I18nKeys = I18nKeys;

	constructor(
		private readonly registerAPIService: RegisterAPIService,
		private readonly userService: UserService,
		private readonly i18nService: NgxI18nService,
		private readonly snackBarService: SnackBarService,
		protected readonly store: Store,
		private modalService: ModalDialogService
	) {
		super(store);
	}

	/**
	 * submitRegistration
	 *
	 * The submitRegistration method will dispatch two actions to the store:
	 * 1. The actions.registerFormData will set the form data to the store so that it can be used in a later step.
	 * 2. The actions.postRegistrationResponse along with the registerAPIService.postRegistrationData will perform the POST and store the response.
	 *
	 * @param data IRegistration
	 * @Returns Observable:void
	 */
	public submitRegistration(data: IRegistration): Observable<void> {
		this.actionInProgressSubject$.next(true);
		this.modalService.openModal(LoadingModalComponent);

		this.store.dispatch(actions.postRegistrationResponse.clear());
		this.store.dispatch(actions.registerFormData.set({ payload: data }));

		return dispatchDataToStore<IPostRegistrationResponseEntity>(
			actions.postRegistrationResponse,
			this.registerAPIService.postRegistrationData(data).pipe(
				tap((response: IPostRegistrationResponseEntity) => {
					const config = this.inviteResponseToSnackBarConfig(data.invites, response);

					// Denis: If all invites have been sent, there is no need for an additional snackbar message.
					if (config.data.toasterType === AlertType.Success) {
						return;
					}

					this.snackBarService.openFromComponent(SnackBarComponent, config);
				})
			),
			this.store
		).pipe(
			catchAndCallThrough(() => {
				this.modalService.instantCloseModal();
				this.actionInProgressSubject$.next(false);
			}, 'throw'),
			catchError((error) => {
				// Denis: if the status-code is 409, return an empty observable to continue the stream.
				if (error.status === 409) {
					return of(null);
				}

				// Denis: if the status-code is not 409, throw the error again to handle it with the snackbar and stop the stream.
				throw error;
			}),
			catchWithSnackBar(
				this.snackBarService,
				{
					title: this.i18nService.getTranslation(this.i18nKeys.Registration.Register.Error.Title),
					type: AlertType.Error
				},
				'throw'
			),
			tap(() => {
				this.actionInProgressSubject$.next(false);
			}),
			switchMap(() => this.modalService.closeModal())
		);
	}

	/**
	 * getMainActivities
	 *
	 * The getMainActivities will request the main activities from the API through registerAPIService.getMainActivities.
	 *
	 * @Returns Observable:IGetMainActivitiesResponse
	 */
	public getMainActivities(): ObservableArray<IMainActivity> {
		return this.registerAPIService.getMainActivities();
	}

	/**
	 * inviteResponseToSnackBarConfig
	 *
	 * The inviteResponseToSnackBarConfig method generates a MatSnackBarConfig object based on the given invites and response.
	 *
	 * @param {IRepresentativeForm[]} invites - The array of representative registration forms.
	 * @param {IPostRegistrationResponseEntity} response - The registration response entity.
	 * @returns {MatSnackBarConfig} - The generated MatSnackBarConfig object.
	 * @private
	 */
	private inviteResponseToSnackBarConfig(
		invites: { [key: string]: IRepresentativeForm } = {},
		response: IPostRegistrationResponseEntity
	): MatSnackBarConfig {
		const { failedInvites, hasFailedCopies } = this.getFailedInviteResponseNotifications(
			invites,
			response.metadata.notificaties
		);
		const alertLevel = this.getInviteResponseAlertLevel(failedInvites.length, hasFailedCopies);
		const inviteText = this.parseInviteResponseInviteText(failedInvites);
		const copyText = this.parseInviteResponseCopyText(hasFailedCopies);

		// Denis: generate a config with the above variables and return
		return generateSnackbarConfig({
			title: this.i18nService.getTranslation(this.i18nKeys.Registration.Register.FormNotice.Invites.Title),
			message: copyText === '' ? inviteText : `<p>- ${inviteText}</p><p>- ${copyText}</p>`,
			type: alertLevel
		});
	}

	/**
	 * getFailedInviteResponseNotifications
	 *
	 * The getFailedInviteResponseNotifications method retrieves failed notifications for representative invites.
	 *
	 * @param  {IRepresentativeForm[]} invites - The representative registration forms.
	 * @param  {PostRegistrationNotificationResponseEnity[]} notifications - The post-registration notification responses.
	 * @return {Object} - An object containing invites and copies arrays.
	 */
	private getFailedInviteResponseNotifications(
		invites: { [key: string]: IRepresentativeForm } = {},
		notifications: PostRegistrationNotificationResponseEnity[] = []
	): {
		failedInvites: string[];
		hasFailedCopies: boolean;
	} {
		return Object.values(invites).reduce(
			(acc, curr, index) => {
				return {
					failedInvites:
						// Denis: this needs to be a strict false check to avoid null values.
						notifications[index]?.emailNaarUitgenodigdeVerzonden === false
							? [...acc.failedInvites, curr.representativeEmailField]
							: [...acc.failedInvites],
					// Denis: this needs to be a strict false check to avoid null values.
					// When the hasFailedCopies check is already set to true, avoid an overwrite with a false value
					hasFailedCopies: acc.hasFailedCopies
						? acc.hasFailedCopies
						: notifications[index]?.emailkopieNaarUitnodigerVerzonden === false
				};
			},
			{
				failedInvites: [],
				hasFailedCopies: false
			}
		);
	}

	/**
	 * getInviteResponseAlertLevel
	 *
	 * The getInviteResponseAlertLevel method Returns the alert level based on the number of failed invites and whether there is a failed copy.
	 *
	 * @param {number} failedInviteLength - The number of failed invites.
	 * @param {boolean} hasFailedCopy - Indicates whether there is a failed copy.
	 * @returns {AlertType} - The alert level.
	 * @private
	 */
	private getInviteResponseAlertLevel(failedInviteLength: number = 0, hasFailedCopy: boolean = false): AlertType {
		// Denis: if there are failed invites, return an error.
		if (failedInviteLength > 0) {
			return AlertType.Error;
		}

		// Denis: if there are no failed invites but the copy has failed, return a warning.
		if (hasFailedCopy) {
			return AlertType.Warning;
		}

		// Denis: in all other cases, return a success status.
		return AlertType.Success;
	}

	/**
	 * parseInviteResponseInviteText
	 *
	 * The parseInviteResponseInviteText method parses the invite response invite text, based on the list of failed invites.
	 *
	 * @param {string[]} failedInvites - The list of failed invites.
	 * @returns {string} - The parsed invite response invite text.
	 * @private
	 */
	private parseInviteResponseInviteText(failedInvites: string[] = []): string {
		if (failedInvites.length === 0) {
			return this.i18nService.getTranslation(this.i18nKeys.Registration.Register.FormNotice.Invites.Success);
		}

		return this.i18nService.getTranslation(this.i18nKeys.Registration.Register.FormNotice.Invites.FailedInvites, {
			emails: failedInvites.join(', ')
		});
	}

	/**
	 * parseInviteResponseCopyText
	 *
	 * The parseInviteResponseCopyText method parses the copy text for invite response.
	 *
	 * @param {boolean} hasFailedCopy - Specifies if the copy text should indicate a failure.
	 *
	 * @returns {string} - The parsed copy text.
	 */
	private parseInviteResponseCopyText(hasFailedCopy: boolean = false) {
		if (!hasFailedCopy) {
			return '';
		}

		return this.i18nService.getTranslation(this.i18nKeys.Registration.Register.FormNotice.Invites.FailedCopies);
	}
}
